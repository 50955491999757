<template>
  <div class="outLinePart">
    <div class="cookPart">
      <el-form ref="dataForm" class="centerForm" :model="dataForm">
        <el-form-item label="订单支付金额：" prop="balance">
          <el-input v-model.number="dataForm.balance" ref="payCode" />
        </el-form-item>
        <el-form-item label="小程序付款码：" prop="paymentCodeId">
          <el-input
            v-model="dataForm.paymentCodeId"
            ref="paymentCodeId"
            @keyup.enter.native="dataFormSubmit"
          />
        </el-form-item>
        <div class="rightConsole">
          <el-button @click="dataFormSubmit">结算</el-button>
        </div>
      </el-form>
    </div>
    <!--    <div class="footerCon">-->

    <!--    </div>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,

      dataForm: {
        balance: '',
        paymentCodeId: '',
      },
    };
  },
  methods: {
    // 初始化
    init() {
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
      });
    },
    // 表单提交
    // 表单提交
    dataFormSubmit() {
      if (this.dataForm.balance) {
        this.$http({
          url: `/mall/paymentCode/pay`,
          data: {
            balance: this.dataForm.balance,
            paymentCodeId: this.dataForm.paymentCodeId,
          },
          method: 'post',
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: data.msg,
              type: 'success',
              duration: 1500,
            });
          }
        });
        this.$nextTick(() => {
          // this.dataForm.balance = null;
          this.dataForm.paymentCodeId = null;
        });
      } else {
        // this.dataForm.balance = null;
        this.dataForm.paymentCodeId = null;
      }
    },
  },
};
</script>
<style lang="scss">
.outLinePart {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  .cookPart {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    .centerForm {
      position: relative;
      width: 60vw;
      height: 50vh;
      margin-left: auto;
      margin-right: auto;
      .el-form-item {
        margin-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
        .el-form-item__label {
          line-height: 60px;
          font-size: 40px;
          text-align: center;
          float: none;
        }
        .el-form-item__content {
          line-height: 60px;
          flex: 1;
          .el-input {
            width: 100%;
            .el-input__inner {
              height: 60px;
              font-size: 28px;
            }
          }
        }
      }
      .rightConsole {
        position: relative;
        .el-button {
          padding: 0;
          width: 100%;
          border-radius: 0;
          background-color: #409eff;
          color: #fff;
          line-height: 60px;
          border: none;
          font-size: 28px;
        }
      }
    }
  }
}
</style>
